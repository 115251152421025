body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  /*background: #000;*/
  /*overflow: hidden;*/
}

a,
button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.content {
  color: #607464;
  font-size: 16px;
  line-height: 25px;
}

.title {
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: bold;
}

.title,
.green-text {
  color: #265a32;
}
